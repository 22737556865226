<template>
    <div class="wrap" :class="{hasborder:borderFlag}">
        <div class="left">
            <div class="title">
                <span class="block" :style="rewardTypeStyle">
                    {{ item | formatTagText(this) }}{{ item | formatRewardType2Name }}
                </span>
                {{ item.activityName }}
            </div>
            <div class="info-wrap">
                <div class="time-wrap">
                    活动时间：<span class="strong">{{ item.startTime | formatTime }} ~ {{ item.endTime | formatTime }}</span>
                </div>
            </div>
            <div class="out-status">
                <div class="status-wrap">
                    <span class="status">活动状态：
                        <span class="strong">{{ item.activityStatus | formatActivityStatus }}</span></span>
                </div>
                <div class="jump" @click="jumpDetail" v-if="jumpFlag">查看活动详情</div>
            </div>
            <div class="reward-wrap">
                <span class="reward">最高奖励：</span>
                <span class="number">
                    &yen;{{ item.zhuankeRewardActivityBenefitRule.rewardLimit }}
                </span>
            </div>
        </div>
        <div class="right">
            <el-popover
                placement="top"
                width="250"
                trigger="click"
            >
                <select-position
                    @hidePosition="hidePosition"
                    v-if="singleposition"
                    @confirmPosition="confirmPosition"
                />
                <div v-if="showPromotionButton" class="button-wrap" slot="reference" ref="button" @click="showPosition">
                    <div>{{ item.activityType | formatPromotionText }}</div>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
import selectPosition from '../goodsPromotion/selectPromotionPosition.vue';
import format from '../../utils/format-time.js';
import Bus from '../../utils/bus.js';
import { formatRewardType2Name, REWARD_ACTIVITY_STATUS, REWARD_ACTIVITY_TYPES_MAP } from '../../utils/reward';
import { bizTypeFindByKey } from '../../utils/mapBizType.js';

export default {
    name: 'rewardModel',
    components: {
        selectPosition
    },
    data() {
        return {
            singleposition: false,
            showPromotionButton: false
        };
    },
    filters: {
        formatTime(time) {
            return format(time);
        },

        formatRewardType2Name,

        formatActivityStatus(status) {
            return REWARD_ACTIVITY_STATUS.get(status) || '';
        },

        formatPromotionText(type) {
            if (type === REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_NEW||
                type === REWARD_ACTIVITY_TYPES_MAP.TMALL_ZHUANKE_INVITE_NEW||
                type === REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ZHUANKE_INVITE_NEW) {
                return '新人最爱商品一键推广';
            }
            return '一键推广活动';
        },
        formatTagText(item, that){
            const _this= that;
            switch (item.activityType) {
            case 'TMALL_ZHUANKE_INVITE_NEW':
            case 'TMALL_FLOW_USR_CPA':
                return '手猫';
            // 区分 bizType = 2
            case 'TAOBAO_ORDER_NUM_REWARD':
            case 'TAOBAO_ZHUANKE_INVITE_NEW':
            case 'TAOBAO_GMV_REWARD' :
            case 'TAOBAO_GMV_RATE' :
            case 'TAOBAO_RED_ORDER_NUM_REWARD':
                return '';
            default:
                return _this.bizTypeFindByKey(item.bizType, 'tagText');
            }
        }
    },
    props: {
        item: {
            type: Object
        },
        jumpFlag: {
            type: Boolean,
            default: true
        },
        borderFlag: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        rewardTypeStyle() {
            if(this.item.activityType === REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_NEW ||
               this.item.activityType === REWARD_ACTIVITY_TYPES_MAP.TMALL_ZHUANKE_INVITE_NEW||
               this.item.activityType === REWARD_ACTIVITY_TYPES_MAP.TAOBAO_ZHUANKE_INVITE_NEW) {
                return {
                    background: 'rgb(86, 128, 243)'
                };
            }
            if(this.item.activityType === REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_VIP) {
                return {
                    background: '#2A2520',
                    color: '#ECC9A7'
                };
            }
            return {
                background: '#ff0000'
            };
        }
    },
    created() {
        this.item.startTime = format(this.item.startTime);
        this.item.endTime = format(this.item.endTime);
        /** 
         * 特殊密令词活动
         * hiddenShare 隐藏分享按钮 */
        if(this.item.activityStatus === REWARD_ACTIVITY_STATUS.IN_PROGRESS && !this.item.hiddenShare){
            this.showPromotionButton = true;
        }
        
    },
    methods: {
        bizTypeFindByKey,
        jumpDetail() {
            this.$router.push({
                path: '/promotion/reward/rewardDetail',
                query: {
                    activityId: this.item.activityId
                }
            });
        },
        showPosition() {
            this.singleposition = true;
        },
        hidePosition() {
            this.$refs.button.click();
        },
        async confirmPosition(e) {
            this.$refs.button.click();
            const data = await this.$request({
                url: '/api/rewardPromotion/instantPromotionRewardActivity',
                params: {
                    activityId: this.item.activityId,
                    positionId: e
                }
            });
            data.activityType = this.item.activityType;
            Bus.$emit('showRewardDialog', data);
        }
    }
};
</script>

<style lang='scss' scoped>
.hasborder {
    border: 1px solid #E3E3E3;
    border-radius: 4px;
}
.wrap {
    margin-top: 16px;
    background: #fff;
    padding: 13px 30px 10px 11px;


    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
        max-width: 850px;
        .title {
            font-size: 16px;
            font-weight: 700;
            color: #333;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 10px;
            .block {
                display: inline-block;
                padding: 0 10px;
                min-width: 50px;
                height: 24px;
                color: #fff;
                text-align: center;
                border-radius: 12px;
            }
            .reward {
                background: #ff0000;
            }
            .pullNew {
                background: rgb(86, 128, 243);
            }
            .vip{
                background: #2A2520;
                color: #ECC9A7;
            }

        }
        .info-wrap {
            display: flex;
            margin-top: 6px;
            .time-wrap{
                margin-right: 50px;
                font-size: 12px;
                color: #333333;
                .strong {
                    color: #151515;
                    font-weight: 700;
                }

            }
        }
        .out-status {
            display: flex;
            .status-wrap {
                margin-right: 20px;
                margin-top: 6px;
                font-size: 12px;
                color: #333333;
                .strong {
                    color: #151515;
                    font-weight: 700;
                }

            }
            .jump {
                margin-top: 6px;
                color: #409Eff;
                text-decoration: underline;
                cursor: pointer;
                font-size: 12px;
            }
        }
        .reward-wrap {
            padding-top: 11px;
            .reward {
                font-size: 12px;
                color: #333333;
            }
            .number {
                color: #FF1E32;
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
    .right {
        .button-wrap {
            width: 160px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: linear-gradient(90deg, #FF1E32 0%, #FF3264 100%);
            border-radius: 21px;
            font-weight: 700;
            color: #FFF;
            cursor: pointer;
            &:hover {
                color: #fff;
                background: #FF4F5D ;
                border: 1px solid #FF4F5D ;
            }
        }
    }
}
</style>
