<template>
    <div class="out-wrap" v-show="dialogFlag">
        <div class="wrap" v-show="dialogFlag">
            <div class="top">
                <div class="title">奖励活动</div>
                <span @click="closeDialog"><i class="el-icon-close" /></span>
            </div>
            <div class="tab-wrap">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane key="text" label="分享文案" name="text" />
                    <el-tab-pane key="link" label="推广链接" name="link" />
                    <el-tab-pane key="image" label="二维码海报图" name="image" />
                    <el-tab-pane key="command" :label="bizTypeDiffObj.commandType" name="command" />
                </el-tabs>
            </div>
 
            <div class="share-text" v-show="textFlag">
                <div class="right-wrap">
                    <div class="info-wrap">
                        <div class="title">{{ bizTypeDiffObj.titleTag }}{{ activityType == 'ZHUANKE_INVITE_VIP' ? shareText : title }}</div>
                        <div class="price" v-if="shareTitle">【超值好物】{{ shareTitle }}</div>
                        <div class="link">【购买链接】{{ shortShareLink }}</div>
                    </div>
                    <div class="button-wrap">
                        <div class="button" @click="copyText">复制文案</div>
                    </div>
                </div>
            </div>  

            <div class="share-text" v-show="commandFlag">
                <div class="right-wrap">
                    <div class="info-wrap">
                        <div class="title">{{ bizTypeDiffObj.titleTag }}{{ activityType == 'ZHUANKE_INVITE_VIP' ? shareText : title }}</div>
                        <div class="price" v-if="shareTitle">【超值好物】{{ shareTitle }}</div>
                        <div class="link">【购买链接】{{ shortShareLink }}</div>
                        <div class="kouling">
                            ￥{{ kouling }}￥{{ resetKoulingTitle }}，复制这段描述后，打开{{ bizTypeDiffObj.appName }}
                        </div>
                    </div>
                    <div class="button-wrap">
                        <div class="button" @click="copyCommand">复制文案</div>
                    </div>
                    <div class="remark">不要更改文案中的{{ bizTypeDiffObj.commandType }}和活动链接哦~</div>
                </div>
            </div>  

            <div class="share-link" v-show="linkFlag">
                <div class="short-link">
                    <div class="desc">短链接</div>
                    <div class="bottom">
                        <div class="input">
                            {{ shortShareLink }}
                        </div>
                        <div class="button" @click="copyShortLink">复制链接</div>
                    </div>
                </div>  
                <div class="long-link">
                    <div class="desc">长链接</div>
                    <div class="bottom">
                        <div class="input">
                            {{ shareLink }}
                        </div>
                        <div class="button" @click="copyLongLink">复制链接</div>
                    </div>
                </div>
            </div> 
            <div class="share-image" v-show="imgFlag">
                <div class="img-wrap" ref="shareImg">
                    <img :src="shotUrl" alt="">
                </div>
                <div class="button" @click="copyShareImg">复制图片</div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '../../utils/bus.js';
import { snapActivityHtml } from '../../utils/snap-html.js';
import generateQRCode from '../../utils/generate-qrcode.js';
import copy from '../../utils/copy.js';

export default {
    name: 'activityDialog',
    data() {
        return {
            dialogFlag: false,
            textFlag: true,
            linkFlag: false,
            imgFlag: false,
            commandFlag: false,
            activeName: 'text',
            pageInfo: null,
            title: '',
            shareTitle: '',
            shortShareLink: '',
            shareLink: '',
            miniShareLink: '',
            qrcode: '',
            shotUrl: '',
            kouling: '',
            brandName: '',
            shareText: '',
            activityType: '',
            //introduce: '',
            bizType: 0
        };
    },
    computed: {
        bizTypeDiffObj() {
            return {
                titleTag: this.bizType === 1 ? '【猫享自营】' : '【考拉海购】',
                appName: this.bizType === 1 ? '天猫App' : '考拉海购APP',
                commandType: this.bizType === 1 ? '猫享口令' : '考拉口令'
            };
        },
        resetKoulingTitle() {

            if(this.activityType == 'ZHUANKE_INVITE_VIP' && this.shareText){
                return `【${this.shareText}】`;
            } 
            
            if(this.shareTitle) {
                return `【${this.shareTitle}】`;
            }

            return '';
        }
    },
    created() {
        /* eslint-disable */
        Bus.$on('showRewardDialog', (data) => {
            console.log(data);
            this.pageInfo = data;
            if(this.pageInfo) {
                this.bizType = this.pageInfo.bizType || 0;
                this.title = this.pageInfo.title;
                this.kouling = this.pageInfo.zhuankeShareUrl.shortKouling || '';
                this.brandName = this.pageInfo.brandName;
                this.activityType = this.pageInfo.activityType;
                //this.introduce = this.pageInfo.introduce;
                this.shareTitle = this.pageInfo.shareTitle;
                this.shareText = this.pageInfo.shareText;
                this.shortShareLink = this.pageInfo.zhuankeShareUrl.shortShareLink;
                this.shareLink = this.pageInfo.zhuankeShareUrl.shareLink;
                this.miniShareLink = this.pageInfo.zhuankeShareUrl.miniShareLink;

                this.qrcode = generateQRCode(this.shortShareLink);
                const goodsImages= this.pageInfo.goodsList;
                     Object.assign(this.pageInfo, {goodsImages,desc:this.pageInfo.activityType == 'ZHUANKE_INVITE_VIP' ? this.shareText : this.shareTitle});
                snapActivityHtml(
                    {
                        pageInfo: this.pageInfo,
                        qrcode: this.qrcode
                    }
                ).then((e) => {
                    this.shotUrl = e;
                });

                
          
            this.dialogFlag = true;
            }
        });
        /* eslint-disable */
    },
    methods: {
        /* eslint-disable */
        handleClick() {
             if(this.activeName === 'text') {
                this.textFlag = true;
                this.linkFlag = false;
                this.imgFlag = false;
                this.commandFlag = false;
            }
            if(this.activeName === 'link') {
                this.textFlag = false;
                this.linkFlag = true;
                this.imgFlag = false;
                this.commandFlag = false;
            }
            if(this.activeName === 'image') {
                this.textFlag = false;
                this.linkFlag = false;
                this.imgFlag = true;
                this.commandFlag = false;
            }
             if(this.activeName === 'command') {
                this.textFlag = false;
                this.linkFlag = false;
                this.imgFlag = false;
                this.commandFlag = true;
            }
        },
        /* eslint-disable */
        closeDialog() {
            this.dialogFlag = false;
            this.textFlag = true;
            this.linkFlag = false;
            this.imgFlag = false;
            this.commandFlag = false;
            this.activeName = 'text';
        },
        copyShortLink() {
            copy(this.shortShareLink,true).then(() => {
                this.successToast();
            })
        },
        copyLongLink() {
            copy(this.shareLink,true).then(() => {
                this.successToast();
            })
        },
        copyText() {
            let str;
            if(this.shareTitle) {
                str =`${this.bizTypeDiffObj.titleTag}${this.title}\n【超值好物】${this.shareTitle}\n【活动链接】${this.shortShareLink}`;
            }
            else {
                str =`${this.bizTypeDiffObj.titleTag}${this.title || this.shareText }\n【活动链接】${this.shortShareLink}`;
            } 
            copy(str,true).then(() => {
                this.successToast();
            })
        },
         copyCommand() {
            let command;
            if(this.shareTitle) {
                command = `${this.bizTypeDiffObj.titleTag}${this.title}\n【超值好物】${this.shareTitle}\n【活动链接】\n￥${this.kouling}￥\n${this.shortShareLink}【${this.shareTitle}】，复制这段描述后，打开${this.bizTypeDiffObj.appName}`;
            }else {
                command = `${this.bizTypeDiffObj.titleTag}${this.title || this.shareText}\n【活动链接】\n￥${this.kouling}￥\n${this.shortShareLink}【${this.shareTitle || this.shareText}】，复制这段描述后，打开${this.bizTypeDiffObj.appName}`;
            }
            copy(command,true).then(() => {
                this.successToast();
            })
        },
        copyShareImg() {
            copy(this.$refs.shareImg,false).then(() => {
                this.successToast();
            })
        },
        successToast() {
             this.$message({
                message: '复制成功',
                type: 'success'
            });
        }
    }
};
</script>

<style scoped lang='scss'>
.out-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap {
    width: 790px;
    min-height: 620px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    z-index: 999;
    .top {
        display: flex;
        justify-content: space-between;
        margin: 16px 18px;
        font-size: 16px;
        font-weight: 700;
    }
    .tab-wrap{
        margin: 10px 30px;
    }
    .share-text{
        margin: 0px 30px;
        padding-top: 20px;
        .right-wrap {
            .info-wrap {
                margin: 0 auto;
                min-height: 124px;
                width: 727px;
                padding: 18px 10px 15px 11px;
                background: #f9f9f9;
                border: 1px solid #e3e3e3;
                border-radius: 8px;
                .title {
                    white-space: nowrap;      
                    overflow: hidden;        
                    text-overflow: ellipsis;
                    margin-bottom: 10px;
                }
                .price {
                    margin-bottom: 10px;
                }
                .kouling {
                    padding-top: 10px;
                    padding-left: 5px;
                }
            }
            .remark {
                color: #999;
                font-size: 12px;
                width: 727px;
                text-align: center;
                padding-top: 8px;
            }
            .form-wrap {
                padding-top: 30px;
                padding-left: 20px;
                .button {
                    margin-left: 30px;
                }
            }
            .button {
                margin: 0 auto;
                margin-top: 33px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                height: 32px;
                border: 1px solid #FF1E32;
                border-radius: 21px;
                color: #FF1E32;
                cursor: pointer;
                &:hover {
                    color: #fff;
                    background: #FD1E32;
                    border: 1px solid #FD1E32;
                }
            }
        }
    }
    .share-link {
        margin: 10px 10px;
        font-weight: 500;
       .short-link, .long-link {
           margin-left: 15px;
           margin-right: 25px;
           margin-bottom: 20px;
           .desc {
               margin-bottom: 10px;
           }
           .bottom {
               display: flex;
               justify-content: space-between;
               align-items: center;
               .input {
                    width: 582px;
                    height: 40px;
                    line-height: 40px;
                    background: #F9F9F9;
                    border: 1px solid #E3E3E3;
                    border-radius: 8px;
                    font-size: 14px;
                    padding-right: 10px;
                    padding-left: 12px;
                    white-space: nowrap;      
                    overflow: hidden;
                    font-weight: 400;      
               }
               .button {
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   width: 120px;
                   height: 32px;
                   border: 1px solid #FF1E32;
                   border-radius: 21px;
                   color: #FF1E32;
                   cursor: pointer;
                   &:hover {
                        color: #fff;
                        background: #FD1E32;
                        border: 1px solid #FD1E32;
                    }
               }
           }
       } 
    }
    .share-image {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 30px;
        .img-wrap {
            width: 316px;
            margin: 0 auto;
            img {
                width: 100%;
                height: 100%;
                border-radius: 5px 5px 0 0;
            }
        }
        .button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 32px;
            border: 1px solid #FF1E32;
            border-radius: 21px;
            color: #FF1E32;
            margin: 0 auto;
            margin-top: 22px;
            cursor: pointer;
            &:hover {
                color: #fff;
                background: #FD1E32;
                border: 1px solid #FD1E32;
            }
        }
    }
}
</style>